import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useRef, useState } from "react";
import CategoryGrid from "./CategoryGrid/CategoryGrid";
import { currencyFormat } from "../../../../../utils/currencyFormat";
import ClearIcon from "@mui/icons-material/Clear";
import {Keyboard} from "./Keyboard/Keyboard";
import {
    DivContainer,
    DivKeyboardRow,
    DivKeyboardWrapper,
    DivScrollable,
    DivSumButtons,
    DivSumPreview, DivUndo,
    DivValuesToSum,
    DivValuesToSumStr,
    OutlinedInputFullWidth
} from "./styled";

export interface Props {
    open: boolean
    onClose: () => void
    onEnter: (value: number, comment?: string, categoryId?: number) => void
    valueDefault?: number
    commentDefault?: string
}

const TransactionDialog = ({ onClose, open, onEnter, valueDefault, commentDefault }: Props) => {
    const [values, setValues] = useState<number[]>(valueDefault ? [valueDefault] : []);
    const [valueStr, setValueStr] = useState<string>(valueDefault?.toString() || '');
    const [comment, setComment] = useState<string>(commentDefault || '');
    const ref = useRef<HTMLInputElement>(null);

    const value = parseFloat(valueStr) >= 0 ? parseFloat(valueStr) : undefined;
    const valuesSum = values.reduce((sum, value) => sum + value, 0) + (value || 0);
    const valuesStr = values.length ? values.map((value) => currencyFormat(value)).join(' + ') + ' +' : '';
    const valuesSumStr = values.length ? ` = ${currencyFormat(valuesSum)}р` : '';

    const handleClearValue = () => {
        setValueStr('');
    }

    const handleClearValues = () => {
        setValueStr('');
        setValues([]);
    }

    const handleChange = (_value: string) => {
        if (_value === '.' && valueStr.length === 0) {
            return;
        }
        if (_value === '.' && valueStr.includes('.')) {
            return;
        }
        setValueStr(`${valueStr}${_value}`);
    }

    const handleChangeComment = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    }

    const handleSave = () => {
        onClose();
        if (valuesSum)
            onEnter(valuesSum, comment === '' ? undefined : comment);
    }

    const handleAdd = () => {
        if (value) {
            setValues((currentValues) => [...currentValues, value]);
            setValueStr('');
        }
    }

    const handleEnter = (e: React.KeyboardEvent) => {
        if (e.key !== 'Enter')
            return;
        e.preventDefault();
        handleAdd();
    }

    const handleSaveWithCategory = (categoryId: number) => {
        onClose();
        if (valuesSum)
            onEnter(valuesSum, comment === '' ? undefined : comment, categoryId);
    }

    useEffect(() => {
        if (open) return;
        setValueStr(valueDefault?.toString() || '');
        setValues(valueDefault ? [valueDefault] : []);
        setComment(commentDefault || '');
    }, [open, valueDefault, commentDefault]);

    return (
        <Dialog onClose={onClose} open={open} fullScreen>
            <DivContainer>
                <DivScrollable>
                    <DivValuesToSum>
                        <DivValuesToSumStr>{valuesStr}</DivValuesToSumStr>
                        <div>
                            {!!values.length && (
                                <IconButton
                                    onClick={handleClearValues}
                                >
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </div>
                    </DivValuesToSum>
                    <OutlinedInputFullWidth
                        placeholder='Cумма'
                        id="adornment-sum"
                        type='text'
                        value={valueStr}
                        ref={ref}
                        disabled
                        endAdornment={
                            <DivSumButtons>
                                <DivSumPreview>{valuesSumStr}</DivSumPreview>
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleAdd}
                                        disabled={!value}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </InputAdornment>
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClearValue}
                                        disabled={value === undefined}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            </DivSumButtons>
                        }
                    />

                    <OutlinedInputFullWidth
                        placeholder='Комментарий'
                        type='text'
                        value={comment}
                        onChange={handleChangeComment}
                        onKeyDown={handleEnter}
                    />

                    <Button
                        onClick={handleSave}
                        endIcon={<CheckIcon />}
                        disabled={!valuesSum}
                        style={{ width: '100%' }}
                    >
                        Добавить без категории
                    </Button>

                    <CategoryGrid
                        onSelect={handleSaveWithCategory}
                    />
                </DivScrollable>
                <DivKeyboardRow>
                    <DivUndo>
                        <UndoIcon onClick={onClose} />
                    </DivUndo>
                    <DivKeyboardWrapper onClick={(e) => e.stopPropagation()}>
                        <Keyboard onChange={handleChange} />
                    </DivKeyboardWrapper>
                </DivKeyboardRow>
            </DivContainer>
        </Dialog>
    );
}

export default TransactionDialog;
