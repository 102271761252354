import {styled} from "@mui/material/styles";
import {OutlinedInput} from "@mui/material";

export const DivContainer = styled('div')({
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
});

export const DivScrollable = styled('div')({
    overflow: 'auto',
});

export const DivValuesToSum = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export const DivValuesToSumStr = styled('div')({
    flexGrow: 1,
});

export const DivSumButtons = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export const DivSumPreview = styled('div')({
    color: '#ccc',
    whiteSpace: 'nowrap',
});

export const DivKeyboardRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: '#ececec',
});

export const DivUndo = styled('div')({
    display: 'flex',
    width: '30%',
    color: 'lightgray',
    alignItems: 'center',
    justifyContent: 'center',
});

export const DivKeyboardWrapper = styled('div')({
    width: '70%',
});

export const OutlinedInputFullWidth = styled(OutlinedInput)({
    width: '100%',
});