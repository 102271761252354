export function reloadWithoutCache () {
    if('caches' in window){
        caches.keys().then((names) => {
            names.forEach(name => {
                caches.delete(name);
            })
        });

        // @ts-ignore
        window.location.reload(true);
    }
}